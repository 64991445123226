
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import * as Yup from "yup";

export default defineComponent({
  name: "sign-in",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const submitButton = ref<HTMLButtonElement | null>(null);
    const toggleVisibility = ref<boolean>(true);

    //Create form validation object
    const resetPasswordValidator = Yup.object().shape({
      password: Yup.string()
        .required("Le mot de passe est requis")
        .min(
          10,
          "Ce mot de passe est trop court. Il doit contenir au moins 10 caractères."
        )
        .matches(/[a-z]/, "Ce mot de passe doit contenir au moins 1 minuscule.")
        .matches(/[A-Z]/, "Ce mot de passe doit contenir au moins 1 majuscule.")
        .matches(/\d/, "Ce mot de passe doit contenir au moins 1 chiffre.")
        .matches(
          /[~!@#$%^&*()_+{}":;'\\[\],.\\/=+€°§¿•±¥Ø-]/,
          "Ce mot de passe doit contenir au moins 1 caractère spécial."
        ),
      password_confirm: Yup.string()
        .required("La confirmation du mot de passe est requise")
        .oneOf(
          [Yup.ref("password"), null],
          "Les mots de passe doivent correspondre"
        ),
    });

    //Form submit function
    const onSubmitResetPassword = async (values) => {
      if (submitButton.value) {
        // eslint-disable-next-line
        submitButton.value!.disabled = true;
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      values = { ...values, ...route.query };
      await store
        .dispatch(Actions.RESET_PASSWORD, values)
        .then(() => {
          router.push({ name: "homepage" });
        })
        .catch(() => {
          router.push({ name: "password-reset" });
        });

      submitButton.value?.removeAttribute("data-kt-indicator");
      // eslint-disable-next-line
      submitButton.value!.disabled = false;
    };

    return {
      onSubmitResetPassword,
      resetPasswordValidator,
      submitButton,
      toggleVisibility,
    };
  },
});
